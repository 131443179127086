var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "350"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) { return null; }
        _vm.dialogOpen = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
            attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "color": "error"
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" " + _vm._s(_vm.actionButtonText) + " ")])];
      }
    }]),
    model: {
      value: _vm.dialogOpen,
      callback: function callback($$v) {
        _vm.dialogOpen = $$v;
      },
      expression: "dialogOpen"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "text-h5"
  }, [_vm._t("default")], 2), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialogOpen = false;
      }
    }
  }, [_vm._v(" Avbryt ")]), _c('v-btn', {
    attrs: {
      "color": "error",
      "text": ""
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v(" Bekreft ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }