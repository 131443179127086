
import { defineComponent, ref } from "@vue/composition-api";

export default defineComponent({
  name: "BaseConfirmDialog",
  components: {},
  props: {
    actionButtonText: {
      type: String,
      default: "Slett",
    },
  },
  emits: ["confirm"],
  setup(props, { emit }) {
    const dialogOpen = ref(false);
    const onConfirm = () => {
      dialogOpen.value = false;
      emit("confirm");
    };
    return { dialogOpen, onConfirm };
  },
});
